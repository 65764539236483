.pagination{
  display: flex;
  list-style-type: none;
  margin-top: 20px;
}

.pageItem:not(:first-child) {
  margin-left: -1px;
}

.pageLink{
  border: 1px solid #dee2e6;
  padding: 2px 8px;
  background-color: #fff;
  display: block;
  transition: color .15s;
  color: #0a58ca;
  user-select: none;

  &:hover{
    background-color: #e9ecef;
  }
}

.active .pageLink{
  color: #fff;
  border-color: #0d6edf;
  background-color: #0d6edf;
}